import React from 'react'
import Founder from '../components/Founder/Founder';
import ManagementTeam from '../components/ManagementTeam/ManagementTeam';
import Gallery from '../components/Gallery/Gallery';
import Contact from '../components/Contact/Contact';
import HumanRightsInfo from '../components/OtherPages/Humanrightsinfo';
import Introduction from '../components/OtherPages/Introduction';
import OurRights from '../components/OtherPages/OurRights';
import Banner from '../components/Slider/Banner';
import LetestActivity from '../components/LetetsActivity/LetestActivity';
import Objective from '../components/Objective/Objective';



const Home = () => {
    return (
        <>
            <Banner/>
            {/* <LetestActivity/> */}
            <Founder/>
            <HumanRightsInfo/>
            <OurRights/>
            <Introduction/>
            <Objective/>
            <Gallery/>
            <ManagementTeam/>
            <Contact/>    
        </>
    )
}

export default Home