import React, { useEffect, useState } from 'react'
import './member.css'
import { MdCardMembership } from "react-icons/md";
import axios from 'axios';
import { API } from '../../../constant';
import MemberPopup from './MemberPopup';

const Member = () => {

  const [memberData, setMemberData] = useState([]);

  const [MemberItemData, setMemberItemData] =useState({});

  useEffect(() => {
    function getMemberData(){
      axios.get(`${API}/admin/membership`)
      .then((res) => {
        setMemberData(res.data?.data);
      })
    }
    getMemberData()
  }, [])

  const isItemSelected = () =>{
    console.log(Object.keys(MemberItemData).length > 0);
    return Object.keys(MemberItemData).length > 0; 
  }
  
  
  return (
    <div className="admin-membership-page">
    {isItemSelected() && 
    <MemberPopup memberData={MemberItemData} onClose={() => setMemberItemData({})}/>}  
      <div className="header">
        <h4>MemberShip</h4>
        <p><MdCardMembership />MemberShip</p>
      </div>
      <div className="member-data">
        <div className="responsiveTable ">

          <table className="styled-table striped AdminMembershipTable">
            <thead>
              <tr>
                <th>Id</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Contact No</th>
                <th>District</th>
                <th>Verified</th>
                <th>Verified By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                memberData.map((memberItem, idx) => (
                  <tr>
                    <td>{idx+1}</td>
                    <td>{memberItem.full_name}</td>
                    <td>{memberItem.email}</td>
                    <td>{memberItem.contact_no}</td>
                    <td>{memberItem.district}</td>
                    <td>{memberItem.verified ? "Verified": "Not Verified"}</td>
                    <td>{memberItem?.verified_by?.name}</td>
                    <td><button onClick={()=> setMemberItemData(memberItem)}>View</button></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}

export default Member