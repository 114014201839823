import axios from "axios";
import { API, BASE_URL } from "../constant";

export const UploadFileToServer = async (file, customFolder, usage="") => {
    try {
        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append("directory", customFolder);
        formdata.append("usage", usage);
        return axios.post(`${BASE_URL}/upload`, formdata);
    } catch (error) {
        throw new Error("Error uploading file")
    }
}