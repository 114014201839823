import React, { useState } from 'react'
import './membership.css'
import { API } from '../../constant';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UploadFileToServer } from '../../functions/UploadFile';


const Membership = () => {

    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState(null);
    const [otpGenerated, setOtpGenerated] = useState(false);

    const [filedata, setFiledata] = useState({
        aadhar_pdf_url: "",
        police_verification_url: "",
        candidate_photo: "",
    })
    const [formdata, setFormData] = useState({
        full_name: "",
        dob: "",
        gender: "",
        blood_group: "",
        contact_no: "",
        email: "",
        address: "",
        district: "",
        pincode: "",
        aadhar_no: "",
        aadhar_pdf_url: "",
        police_verification_url: "",
        candidate_photo: "",
        reference: "",
    })


    const handleFormInput = (e) => {
        const { name, value } = e.target;
        setFormData(p => ({
            ...p,
            [name]: value
        }));
    }

    // console.log(formdata);


    const handleForm1 = (e) => {
        e?.preventDefault();
        if (!formdata.full_name || !formdata.dob || !formdata.gender || !formdata.blood_group) {

            console.log(formdata);
            return toast.warning("Fill all data");
        }
        setStep(p => p + 1);
    }
    const handleForm2 = (e) => {
        e?.preventDefault();
        if (!formdata.contact_no || !formdata.email || !formdata.address || !formdata.district || !formdata.pincode) {
            console.log(formdata);
            return toast.warning("Fill all data");
        }
        setStep(p => p + 1);
    }

    const generateOTP = async (e) => {
        e?.preventDefault();
        if (!formdata.aadhar_no || !formdata.aadhar_pdf_url || !formdata.police_verification_url || !formdata.candidate_photo) {
            console.log(formdata);
            return toast.warning("Fill all data");
        }
        axios.post(`${API}/admin/membership/generate-otp`, formdata)
            .then((res) => {
                const { status } = res.data;
                if (status === "success") {
                    setOtpGenerated(true);
                    toast.success("OTP Sent to your email. Please check and verify.")
                }
            }).catch((err) => {
                console.log(err);
            })
    }
    const verifyOTP = async (e) => {
        e?.preventDefault();
        axios.post(`${API}/admin/membership/verify-otp`, { email: formdata.email, otp })
            .then((res) => {
                const { status } = res.data;
                if (status === "success") {
                    toast.success("OTP verified successfully. Your data has been recorded.")
                    window.location.reload();
                }
            }).catch((err) => {
                toast.error("OTP verification failed.")
                console.log(err);
            })
    }


    const handleOTPInput = (e) => {
        setOtp(e.target.value);
    }

    const handleFileUpload = async (e, customFolder, fieldName) => {
        const file = e.target.files[0];

        console.log(fieldName);
        const response = await UploadFileToServer(file, customFolder);
        const { url, file_path } = response.data.data;
        const { status } = response.data;
        setFiledata({
            [fieldName]: file.name
        })
        if (status === "SUCCESS") {
            setFormData(prev => ({
                ...prev,
                [fieldName]: url
            }))
        }
    }
    console.log(filedata);
    console.log(formdata);

    return (
        <div className="member-container">
            <div className='membershipAreaLeft'>
                <form action="">
                    <h3>Check Registration Status</h3>
                    <label htmlFor="">Enter Adharcard No:</label>
                    <input type="phone" className='membershipInput' minLength={12} />
                    <button className='AMFButton'>Check Status</button>
                </form>
                <form action="">
                    <h3>Download Your Id Card</h3>
                    <label htmlFor="">Enter Aadhar No:</label>
                    <input className="membershipInput" type="phone" minLength={12} />
                    <button className='AMFButton'>Download Id Card</button>
                </form>
            </div>
            <div className='MembershipStepFormContainer'>
                <div className={`progress`}>
                    <div className={`actualProgress  progress-${step}`} style={{
                        width: `${step * 33.3}%`
                    }}></div>
                </div>
                <div className="MembershipFormBox">
                    <h3 className='formHeader'>Membership Registation Here</h3>
                    {step === 1 && (
                        <form className='membershipStepForm' onSubmit={handleForm1} id='form1' action="">
                            <h3 className='formSubHeader'>Persnal Info</h3>

                            <div className="inputGroup">
                                <label htmlFor="">Full Name:</label>
                                <input className='membershipInput w-full' name="full_name" value={formdata.full_name} onChange={handleFormInput} type="text" />
                            </div>

                            <div className="inputGroup">
                                <label htmlFor="">Date Of Birth:</label>
                                <input className='membershipInput w-full' name="dob" value={formdata.dob} onChange={handleFormInput} type="date" id="" />
                            </div>

                            <div className="inputGroup">
                                <label htmlFor="">Gender:</label>
                                <select className='membershipInput w-full' name="gender" value={formdata.gender} onChange={handleFormInput}>
                                    <option value="">Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="others">Trancegender</option>
                                </select>
                            </div>

                            <div className="inputGroup">
                                <label htmlFor="">Blood Group:</label>
                                <select name="blood_group" value={formdata.blood_group} onChange={handleFormInput} className='membershipInput w-full'>
                                    <option value="Blood Group">Blood Group</option>
                                    <option value="A+">A+</option>
                                    <option value="A-">A-</option>
                                    <option value="B+">B+</option>
                                    <option value="B-">B-</option>
                                    <option value="AB+">AB+</option>
                                    <option value="AB-">AB-</option>
                                    <option value="O+">O+</option>
                                    <option value="O-">O-</option>
                                </select>
                            </div>

                            <button className='AMFButton' >Next</button>
                        </form>
                    )}

                    {step === 2 && (

                        <form className='membershipStepForm' onSubmit={handleForm2} id='form1' action="">
                            <h3>Contact Details</h3>

                            <div className="inputGroup">
                                <label htmlFor="">Contact No:</label>
                                <input name="contact_no" value={formdata.contact_no} onChange={handleFormInput} className='membershipInput w-full' type="tel" />
                            </div>
                            <div className="inputGroup">
                                <label htmlFor="">Email:</label>
                                <input className='membershipInput w-full' type="email" name="email" value={formdata.email} onChange={handleFormInput} />
                            </div>
                            <div className="inputGroup">
                                <label htmlFor="">Address:</label>
                                <textarea className='membershipInput w-full' name="address" value={formdata.address} onChange={handleFormInput} cols="30" rows="4" />
                            </div>
                            <div className="inputGroup">
                                <label htmlFor="">District:</label>
                                <input className='membershipInput w-full' type="text" name="district" value={formdata.district} onChange={handleFormInput} />
                            </div>
                            <div className="inputGroup">
                                <label htmlFor="">Pin Code:</label>
                                <input className='membershipInput w-full' type="number" name="pincode" value={formdata.pincode} onChange={handleFormInput} />
                            </div>

                            <div className="gridBtnGroup">
                                <button
                                    className='AMFButton'
                                    type='button'
                                    onClick={() => setStep(1)}
                                >
                                    Back
                                </button>
                                <button className='AMFButton btn' type='submit'>Next</button>
                            </div>
                        </form>
                    )}



                    {step === 3 && (
                        <form onSubmit={otpGenerated ? verifyOTP : generateOTP} className='membershipStepForm' id='form1' action="">
                            <h3>KYC Documents</h3>
                            <div className="inputGroup">
                                <label htmlFor="">Adharcard No:</label>
                                <input className='membershipInput w-full' name="aadhar_no" value={formdata.aadhar_no} onChange={handleFormInput} type="number" minLength={12} maxLength={12} />
                            </div>
                            <div className="inputGroup">
                                <label htmlFor="">Upload Adhar PDF:</label>
                                <input
                                    type="file"
                                    className='membershipInput w-full'
                                    name="aadhar_pdf_url"
                                    // value={filedata.aadhar_pdf_url}
                                    onChange={async (e) => await handleFileUpload(e, "aadhar", "aadhar_pdf_url")}
                                />
                            </div>
                            <div className="inputGroup">
                                <label htmlFor="">Police Verification:</label>
                                <input
                                    onChange={async (e) => await handleFileUpload(e, "police", "police_verification_url")}
                                    className='membershipInput w-full'
                                    type="file"
                                    name="police_verification_url"
                                // value={filedata.police_verification_url}
                                />
                            </div>

                            <div className="inputGroup">
                                <label htmlFor="">Candidate Photo:</label>
                                <input
                                    type="file"
                                    onChange={async (e) => await handleFileUpload(e, "candidate", "candidate_photo")}
                                    className='membershipInput w-full'
                                    name="candidate_photo"
                                // value={filedata.candidate_photo}
                                />
                            </div>

                            <div className="inputGroup">
                                <label htmlFor="">Reference:</label>
                                <input className='membershipInput w-full' name="reference" value={formdata.reference} onChange={handleFormInput} type="text" />
                            </div>

                            {otpGenerated && (
                                <div className="inputGroup">
                                    <label htmlFor="">OTP:</label>
                                    <input className='membershipInput w-full' name="reference" value={otp} onChange={handleOTPInput} type="text" />
                                </div>
                            )}

                            <div className="gridBtnGroup">
                                <button
                                    className='AMFButton'
                                    type='button'
                                    onClick={() => setStep(2)}
                                >
                                    Back
                                </button>
                                <button className='AMFButton btn'>{otpGenerated ? "Submit" : "Generate OTP"}</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Membership