import React, { useMemo, useState } from 'react'
import './login.css'
import loginIMG from '../../../assets/userLogin.png'
import LoginBg from '../../../assets/login-bg.jpg'
import axios from 'axios'
import { API } from '../../../constant'
import {useSearchParams} from 'react-router-dom'
const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleUsernameChange = (e) => {
		setUsername(e.target.value);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const [urlSearchParman, setUrlSearchParam] = useSearchParams();
    const nextUrl = useMemo(() => urlSearchParman.get("next"), [urlSearchParman]);


  

	// Handle login logic here (e.g., send credentials to a server)

	// For simplicity, log the credentials for now
	const loginUser = (e)=> {
		e.preventDefault();
		axios.post(`${API}/admin/user/login`, {
			email: username,
			password
		}).then((res)=>{
			const {status, token } = res.data;
			if(status !== "success"){
				alert("error")
			}
			else {
					localStorage.setItem("token",token)
					if (nextUrl === "" || nextUrl === undefined || nextUrl === null) {
                        window.location.href = "/admin/dashboard"
                    } else {
                        window.location.href = nextUrl
                    }
			}
		})
	}
	return (
		<div className='login-container'>
			<img src={LoginBg} alt="" />
			<div className="loginwrapperCon">
				<div className="loginwrapper">
					<div className='loginform'>
						<form onSubmit={loginUser} className='AMFLoginForm'>
							<div className="AMFLoginInputGroup">
								<h2 className='heading'>Admin Login</h2>
								<label>Username:</label>
								<input type="text" className='llInput' value={username} onChange={handleUsernameChange} />
							</div>
							<div className="AMFLoginInputGroup">
								<label>
									Password:
								</label>
								<input type="password" className='llInput' value={password} onChange={handlePasswordChange} />
							</div>
							<button className='submitBTN' type="submit">Login</button>
						</form>
					</div>
					<div className='loginImg'>
						<img src={loginIMG} alt="" />
					</div>
				</div >
			</div >
		</div >
	)
}
export default Login