import React from 'react'
import axios from 'axios'
import './MemberPopup.css'
import { API } from '../../../constant'
import { Link } from 'react-router-dom'

const MemberPopup = ({ memberData, onClose }) => {

    const verifyMembership = async (memberId) => {
        try {
            const res = await axios.post(`${API}/admin/membership/verify-membership`, { memberId }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="popup-container-backdrop">

            <div className="popup-container">
                <div className="adminCard member-left">
                    <div className='member-img'>
                        <img src={`${API}/upload/${memberData?.candidate_photo}`} alt="" />
                    </div>
                    <div className="descLine">
                        <b>Name:</b>
                        <p>{memberData?.full_name}</p>
                    </div>
                    <div className="descLine">
                        <b>Date Of Birth:</b>
                        <p>{memberData?.dob}</p>
                    </div>
                    <div className="descLine">
                        <b>Gender:</b>
                        <p>{memberData?.gender}</p>
                    </div>
                    <div className="descLine">
                        <b>Contact No:</b>
                        <p>{memberData?.contact_no}</p>
                    </div>
                    <div className="descLine">
                        <b>Address:</b>
                        <p>{memberData?.address}</p>
                    </div>
                    <div className="descLine lastItem">
                        <button className='AMFButton md' onClick={onClose}>Close</button>
                        {!memberData?.verified && (
                            <button className='AMFButton md' onClick={() => verifyMembership(memberData?._id)}>Verify</button>
                        )}
                    </div>
                </div>


                <div className="adminCard member-right">
                    <h4>Other Information</h4>
                    <div className="descLine">
                        <b>Email:</b>
                        <p>{memberData?.email}</p>
                    </div>
                    <div className="descLine">
                        <b>Reference:</b>
                        <p>{memberData?.reference}</p>
                    </div>
                    <div className="descLine">
                        <b>District:</b>
                        <p>{memberData?.district}</p>
                    </div>
                    <div className="descLine">
                        <b>Pin Code:</b>
                        <p>{memberData?.pincode}</p>
                    </div>
                    <div className="descLine">
                        <b>Aadhar Card No:</b>
                        <p>{memberData?.aadhar_no}</p>
                    </div>

                    <div className="descLine">
                        <b>Aadharcard Pdf:</b>
                        <Link target='_blank' to={`${API}/upload/${memberData?.aadhar_pdf_url}`} className='AMFButton sm'>View</Link>
                    </div>
                    <div className="descLine lastItem">
                        <b>Police Verification:</b>
                        <Link target='_blank' to={`${API}/upload/${memberData?.police_verification_url}`} className='AMFButton sm'>View</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberPopup